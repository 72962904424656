body.page-calculadora,
body.page-calculadora-agendamento,
body.page-nova-calculadora-de-aluguel,
body.page-lp-calculadora-de-aluguel {

    #main-header,
    .footer .content:first-child {
        display: none;
    }

    .page-content {
        padding: 0;
    }

    .paragraph {
        color: #1A1818;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        line-height: 120%;
        margin: 0;
        padding: 0;

        @media (max-width: 767px) {
            line-height: 140%;
            font-size: 16px;
        }

        &.small {
            font-size: 16px;

            @media (max-width: 767px) {
                font-size: 14px;
            }
        }

        &.center {
            text-align: center;
        }
    }

    .calc-header {
        background-color: #F3F3F3;
        height: 100px;

        @media (max-width: 767px) {
            height: 77px;
        }

        .container,
        .row {
            height: 100%;
        }

        h1 {
            color: #1A1818;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px; 
            display: block;
            margin: 0;

            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
    }

    .calc-banner {
        background-color: #000;
        background-position: center;
        background-repeat: no-repeat;
        height: 400px;

        @media (max-width: 767px) {
            display: none;
        }
    }

    .calc-banner-mob {
        background-color: #000;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 240px;

        @media (min-width: 768px) {
            display: none;
        }
    }
    
    .calc-form {
        background: #FFF;
        padding: 70px 0 30px;

        @media (max-width: 767px) {
            padding: 40px 0;
        }

        h2 {
            color: #1A1818;
            font-style: normal;
            line-height: 120%;
            margin: 0;
            padding: 0;

            &.center {
                text-align: center;
            }
        }

        h2 {
            font-size: 28px;
            font-weight: 500;
            margin-bottom: 28px;

            @media (max-width: 767px) {
                font-size: 20px;
                margin-bottom: 16px;
            }
        }

        .subtitle-h2 {
            color: #1A1818;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: block;
            line-height: 26px;

            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 22px;
                width: 330px;
            }

            strong {
                font-weight: 700;
            }

            + .subtitle-h2 {
                margin-top: 28px;

                @media (max-width: 767px) {
                    margin-top: 18px;
                }
            }

            &.line {
                position: relative;

                &:before {
                    content: '';
                    width: 344px;
                    height: 8px;
                    position: absolute;
                    left: 0;
                    bottom: -7px;
                    background: url(../images/calculadora-lp/line.png) 0 0 no-repeat;

                    @media (max-width: 767px) {
                        background-size: contain;
                        width: 242px;
                        bottom: -5px;
                    }
                }
            }
        }

        .result .form-subtitle {
            margin-bottom: 24px;
        }

        .form {
            border-radius: 16px;
            border: 1px solid #E2E2E2;
            background: #FFF;
            width: 100%;
            margin: -284px 0 0;
            padding: 40px;

            @media (max-width: 767px) {
                margin: 30px 0 0;
                padding: 20px;
            }

            &-title {
                color: #1A1818;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%; 
                display: block;
                margin: 0 0 28px;

                @media (max-width: 767px) {
                    font-size: 20px;
                    line-height: 140%;
                    margin: 0 0 20px;
                }

                &.ico {
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 10px;
                        flex-shrink: 0;
                    }
                }
            }

            &-subtitle {
                color: #1A1818;
                font-size: 16px;
                font-weight: 500;
                line-height: 120%;
                margin: 0 0 10px;
                display: block;

                @media (max-width: 767px) {
                    font-size: 14px;
                    line-height: 140%;
                }

                strong {
                    font-weight: 700;
                }

                + p {
                    margin-top: 24px;

                    @media (max-width: 767px) {
                        margin-top: 16px;
                    }
                }
            }

            p {
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
                margin: 0 0 24px;

                @media (max-width: 767px) {
                    font-size: 12px;
                    margin-bottom: 16px;
                }

                strong {
                    font-weight: 700;
                }

                span {
                    font-size: 16px;
                    font-weight: 700;

                    @media (max-width: 767px) {
                        font-size: 14px;
                    }
                }

                &.obs {
                    font-size: 12px;
                    margin: 24px 0 0;

                    @media (max-width: 767px) {
                        font-size: 12px;
                        margin: 16px 0 0;
                    }
                }
            }

            .form-interesse-title {
                color: #1A1818;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px; 
                display: block;
                margin: 20px 0 16px;
            }

            .form-interesse {
                display: flex;
                position: relative;
                cursor: pointer;

                + .form-interesse {
                    margin-top: 16px;
                }

                input {
                    width: 24px;
                    height: 24px;
                    appearance: none; 
                    border: 1px solid #E2E2E2;
                    margin: 0 12px 0 0;
                    border-radius: 4px;
                    flex-shrink: 0;
                    position: relative;
                    z-index: 1;
                    opacity: 0;

                    &:checked ~ .radio-mark {
                        border-color: #ffd300;
                        background-color: #ffd300;

                        &:before {
                            transform: rotate(-45deg);
                            opacity: 1;
                        }
                    }
                }

                .radio-mark {
                    position: absolute;
                    left: 0;
                    top: 0;
                    transition: all 0.2s ease;
                    width: 24px;
                    height: 24px;
                    appearance: none; 
                    border: 1px solid #E2E2E2;
                    border-radius: 4px;

                    &:before {
                        content: '';
                        width: 14px;
                        height: 8px;
                        border-left: 2px solid #000;
                        border-bottom: 2px solid #000;
                        position: absolute;
                        top: 5px;
                        left: 5px;
                        display: block;
                        opacity: 0;
                        transition: all 0.3s 0.1s ease;
                        transform: rotate(0deg);
                    }
                }

                label {
                    color: #1A1818;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 25px; 
                }
            }

            .steps {
                width: 298px;
                height: 44px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                margin: 0 0 24px;

                @media (max-width: 767px) {
                    margin-bottom: 16px;
                }

                &:before,
                &:after {
                    content: '';
                    height: 6px;
                    width: 100%;
                    background: #E0E0E0;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin-top: -3px;
                }

                &:before {
                    width: 100%;
                    background: #E0E0E0;
                }

                &:after {
                    width: 0;
                    background: #FFD300;
                    transition: all 0.3s ease;
                }

                &.s2 {
                    &:after {
                        width: 21%;
                    }
                }

                &.s3 {
                    &:after {
                        width: 50%;
                    }
                }

                &.s4 {
                    &:after {
                        width: 78%;
                    }
                }

                &.s5 {
                    display: none;

                    &:after {
                        width: 100%;
                    }
                }

                .step {
                    width: 44px;
                    height: 44px;
                    flex-shrink: 0;
                    display: flex;
                    background: #E0E0E0;
                    color: #A8A8A8;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 1;
                    position: relative;
                    z-index: 1;
                    border-radius: 50%;
                    align-items: center;
                    justify-content: center;
                    
                    &.active {
                        color: #1A1818;
                    }

                    &:before {
                        content: '';
                        height: 10px;
                        width: 15px;
                        border-left: 2px solid #000;
                        border-bottom: 2px solid #000;
                        position: absolute;
                        top: 14px;
                        left: 14px;
                        opacity: 0;
                        transform: rotate(-5deg);
                        transition: all 0.3s ease;
                    }

                    &.complete {
                        background: #FFD300;
                        font-size: 0;
                        color: transparent;

                        &:before {
                            opacity: 1;
                            transform: rotate(-45deg);
                        }
                    }
                }
            }

            .form-group {
                margin-bottom: 24px;

                @media (max-width: 767px) {
                    margin-bottom: 16px;
                }
                
                .form-control {
                    height: 70px;
                }
            }

            .custom-control.custom-radio .custom-control-label {
                padding: 0;
                display: flex;
                height: 70px;
                justify-content: center;
                align-items: center;

                @media (max-width: 767px) {
                    font-size: 14px;
                }
            }

            .group-interrese,
            .group-idade {
                border: 1px solid #000;
                border-radius: 13px;
                position: relative;
                margin: 0 0 24px;

                @media (max-width: 767px) {
                    margin-bottom: 16px;
                }
            }
        }

        .btn {
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 40px;

            + .btn { 
                margin-top: 10px;

                @media (max-width: 767px) {
                    margin-top: 5px;
                }
            }

        }

        .btn-2 {
            color: #fff;

            svg {
                width: 20px;
                height: 20px;
                display: block;
                margin: 0 10px 0 0;
                fill: #fff;
            }
        }

        .btn-4 {
            color: #fff;
            background: #2E2E2D;

            svg {
                width: 20px;
                height: 20px;
                display: block;
                margin: 0 10px 0 0;
                fill: #fff;
            }

            &:hover {
                background-color: #FFBB00;
            }
        }

        .btn-1,
        .btn-3 {
            svg {
                width: 20px;
                height: 20px;
                display: block;
                margin: 0 10px 0 0;
            }
        }

        .form-result-title {
            color: #000;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: block;
            margin: 0 0 25px;
        }

        .form-result-values {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            justify-content: space-evenly;
            margin: 0 0 40px;

            strong {
                color: #000;
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }

        .form-result-description {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            display: block;

            + .form-result-description {
                margin-top: 24px;
            }
        }
    }

    .calc-locacao {
        background: #F3F3F3;
        padding: 70px 0;

        @media (max-width: 767px) {
            padding: 40px 0;
        }

        h3, h4 {
            color: #1A1818;
            text-align: center;
            font-style: normal;
            line-height: 120%;
            font-weight: 500;

            @media (max-width: 767px) {
                line-height: 140%;
            }
        }

        h3 {
            font-size: 24px;
            margin-bottom: 8px;

            @media (max-width: 767px) {
                font-size: 18px;
            }
        }

        h4 {
            font-size: 20px;
            margin-bottom: 10px;

            @media (max-width: 767px) {
                font-size: 16px;
            }
        }

        .item {
            margin: 40px 0 0;
            padding: 0;

            .ico {
                margin: 0 auto 20px;
                height: 60px;
                width: 60px;
            }

            img {
                display: block;
                margin: 0 auto;
            }
        }
    }

    .calc-vantages {
        background: #FFD300;
        padding: 70px 0;

        @media (max-width: 767px) {
            padding: 40px 0;
        }

        svg {
            margin: 0 0 40px;
            display: block;

            @media (max-width: 767px) {
                margin: 0 auto 20px;
            }
        }

        .item {
            padding: 0 30px 0 50px;
            position: relative;
            margin: 0;

            @media (max-width: 767px) {
                margin-top: 20px;
            }
            
            + .item {
                margin-top: 30px;

                @media (max-width: 767px) {
                    margin-top: 20px;
                }
            }

            svg {
                width: 18px;
                height: 16px;
                display: block;
                position: absolute;
                top: 4px;
                left: 10px;
            }
        }

        h3 {
            color: #1A1818;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            margin: 0 0 8px;

            @media (max-width: 767px) {
                font-size: 18px;
                margin: 0;
            }
        }

        .info {
            display: block;
            color: #1A1818;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 30px;
        }
    }

    .calc-alugue {
        background: #fff;
        padding: 70px 0;

        @media (max-width: 767px) {
            padding: 40px 0;
        }

        .content-text {
            padding: 0 70px;

            @media (max-width: 1199px) {
                padding: 0;
            }
        }

        h2, p {
            margin: 0 0 24px;
        }

        h2 {
            color: #1A1818;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;

            @media (max-width: 767px) {
                font-size: 18px;
            }
        }

        img {
            display: block;
            width: 100%;
            height: auto;

            @media (max-width: 767px) {
                width: 100vw;
                margin: 0 -16px 30px;
            }
        }
    }
}